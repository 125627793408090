<script setup>
const installed = ref(false)
const loading = ref(false)
const chat = ref(false)

const open = () => {
  loading.value = true
  if (!installed.value) {
    const script = document.createElement('script')
    script.innerText = `!function(){var t=window.driftt=window.drift=window.driftt||[];if(!t.init){if(t.invoked)return void(window.console&&console.error&&console.error("Drift snippet included twice."));t.invoked=!0,t.methods=["identify","config","track","reset","debug","show","ping","page","hide","off","on"],t.factory=function(e){return function(){var n=Array.prototype.slice.call(arguments);return n.unshift(e),t.push(n),t}},t.methods.forEach(function(e){t[e]=t.factory(e)}),t.load=function(t){var e=3e5*Math.ceil(new Date/3e5),n=document.createElement("script");n.type="text/javascript",n.async=!0,n.crossorigin="anonymous",n.src="https://js.driftt.com/include/"+e+"/"+t+".js";var o=document.getElementsByTagName("script")[0];o.parentNode.insertBefore(n,o)}}}(),drift.SNIPPET_VERSION="0.3.1",drift.load('gwkkda27vshu');`
    // script.async = true
    document.body.appendChild(script)
  }
  if (window.drift) {
    window.drift.config({
      locale: 'sr-RS',
      verticalOffset: 12,
      horizontalOffset: 12,
      backgroundColor: '#e62243',
      foregroundColor: '#ffffff'
    })
    window.drift.on('ready', (api) => {
      installed.value = true
      // api.widget.hide()
      api.toggleChat()
      // chat.value = true
      loading.value = false
    })
    window.drift.on('chatClose', (api) => {
      api.widget.hide()
      // chat.value = false
      loading.value = false
    })
  }
}
</script>

<template>
  <div
    id="drift-frame-chat"
    class="support"
    @click="open"
  >
    <vSpinner
      v-if="loading"
      :size="32"
    />
    <div
      v-else
      class="fx"
    >
      <vIcon
        :id="!chat ? 'chat-bubble-outline' : 'chat-bubble-outlin'"
      />
    </div>
    <span>Online podrška</span>
  </div>
</template>

<style scoped>
.support {
  --size: 32px;
  --half: calc(var(--size) / 2);
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: var(--half) 0 calc(var(--size) / 4);
  height: var(--size);
  line-height: 1;
  border: 1px solid rgb(var(--color-rgb) / .12);
  border-radius: var(--half);
  & span {
    padding-left: 8px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
  }
}

.fx {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  display: grid;
  place-items: center;
}

@media (max-width: 512px) {
  .support {
    --size: 56px;
  }
}
</style>
