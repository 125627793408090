<script setup>
const x = useToasts()
</script>

<template>
  <div class="toasts">
    <vToast
      v-for="toast, key in x.toasts"
      :key="key"
      :toast="toast"
    />
  </div>
</template>

<style scoped>
.toasts {
  position: fixed;
  bottom: 16px;
  left: 24px;
  width: 100%;
  max-width: 400px;
  z-index: 9999;
}
/* .toasts {
  position: fixed;
  top: 16px;
  right: 24px;
  width: 100%;
  max-width: 400px;
  z-index: 9999;
} */

@media (max-width: 512px) {
  .toasts {
    width: calc(100% - 24px);
    max-width: none;
    left: 12px;
    right: -12px;
    bottom: 4px;
  }
}
</style>
