<script setup>
import { ref } from 'vue'

const loading = ref(false)
const email = ref('')
const toasts = useToasts()
const subscribe = async () => {
  try {
    loading.value = true
    const { title, status } = await $fetch('/api/newsletter', {
      method: 'post',
      body: {
        email_address: email.value,
        status: 'pending'
      }
    })
    const id = +new Date()
    let content
    if (title === 'Member Exists' && status === 400) {
      // You are already on the list!
      content = `<strong>${email.value}</strong> je već na na listi!`
    }
    if (status === 'pending') {
      // To complete subscription process, please click in the email we just sent you!
      content = `Da biste završili proces prijave na newsletter, kliknite na e-poruku koju smo vam upravo poslali!`
    }
    toasts.addToast({
      id, content
    })
    email.value = ''
  }
  catch (error) {
    console.error(error)
  }
  finally {
    loading.value = false
  }
}
</script>

<template>
  <section>
    <div class="wrapper">
      <div>
        <div>
          <div class="h3">
            Prijavi se za bilten
          </div>
          <div class="h4">
            Prijavite se za StilLes bilten i budite uvek na vreme informisani o našim proizvodima,
            dekorima
            i uslugama.
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="form">
            <fieldset>
              <label>Email adresa {{ email }}</label>
              <input
                v-model="email"
                type="email"
                placeholder="ime@domen.com"
                @keyup.enter="subscribe"
              >
            </fieldset>
            <button @click="subscribe">
              <vSpinner
                v-if="loading"
                :size="32"
              />
              <template v-else>
                PRIJAVI SE
              </template>
            </button>
          </div>
          <div class="agree">
            Prijavom na bilten prihvataš <NuxtLink :to="{ name: 'politika-privatnosti' }">Politiku privatnosti
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
section {

  padding: 64px 0 112px;
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  & div {
    flex: 1;
  }
}

.h3 {
  font-weight: 800;
  font-size: 21px;
}

.h4 {
  text-wrap: balance;
}

.form {
  display: flex;
  align-items: flex-end;
  padding-bottom: 8px;
  gap: 24px;
  /* outline: 1px dashed; */
}

fieldset {
  display: flex;
  flex-direction: column;
  flex: 1
}

label {
  font-size: 13px;
  padding-bottom: 4px;
}

input {
  border: 1px solid rgb(var(--color-rgb) /.12);
  padding: 0 24px;
  font-size: 16px;
}

input::placeholder {
  color: rgb(var(--color-rgb) / .66);
}

input:focus::placeholder {
  color: transparent;
}

button,
input {
  border-radius: 4px;
  height: 56px;
}

button {
  background-color: transparent;
  color: var(--color);
  font-size: 14px;
  padding: 0 32px;
  font-weight: 500;
  line-height: 1;
  border-radius: 3px;
  border: 1px solid rgb(var(--color-rgb) / .12);
}

.agree {
  font-size: 13px;
  color: rgb(var(--color-rgb) / .66);

  & a {
    border-bottom: 1px solid;
  }
}

@media (max-width: 512px) {
  section {
    padding: 0 0 64px;
  }
  .h3 {
    font-size: 24px;
    padding-bottom: 8px;
  }
  .form,
  .wrapper {
    flex-direction: column;
  }

  .form {
    align-items: flex-start;
  }

  fieldset,
  input,
  button {
    width: 100%;
  }

  button {
    text-align: center;
  }
}
</style>
