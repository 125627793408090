<template>
  <div>
    <div class="wrapper">
      <div class="logos">
        <div>
          <a
            href="https://www.mastercard.rs/sr-rs/korisnici/pronadite-karticu.html"
            aria-label="mcIDCheck"
          >
            <img
              src="/images/mc_idcheck.png"
              loading="lazy"
              alt="mcIDCheck"
            >
          </a>

          <a
            href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
            aria-label="VisaSecure"
          >
            <img
              src="/images/visa-secure_blu_72dpi.jpg"
              loading="lazy"
              alt="VisaSecure"
              class="mn"
            >
          </a>
        </div>
        <div>
          <a
            href="https://www.bancaintesa.rs/"
            aria-label="Banca Intesa ad Beograd"
          >
            <img
              src="/images/intesa.webp"
              loading="lazy"
              alt="Banca Intesa ad Beograd"
              class="mn"
            >
          </a>
        </div>

        <div>
          <img
            src="/images/mc.png"
            loading="lazy"
            alt=""
          >
          <img
            src="/images/maestro.png"
            loading="lazy"
            alt=""
          >
          <img
            src="/images/dinacard.jpg"
            loading="lazy"
            alt="Dina Card"
          >
          <img
            src="/images/visa.png"
            loading="lazy"
            alt="Visa"
          >
          <img
            src="/images/american_express.png"
            loading="lazy"
            alt="American Express"
            class="mn"
          >
        </div>
      </div>
      <!-- <div class="pt">
        &copy; 2008-{{ new Date().getFullYear() }}. Sva prava zadržana. Opšti uslovi kupovine | <RouterLink
          :to="{ name: 'politika-privatnosti' }">
          Politika privatnosti</RouterLink> |
        Uputstvo za onlajn kupovinu.
      </div> -->
    </div>
  </div>
</template>

<style scoped>
.pt {
  padding-top: 8px;
}

.wrapper {
  font-size: 13px;
  color: rgb(0 0 0 / .66);
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 24px;
  max-width: 1488px;
  margin: 0 auto;
}

.logos {
  padding-top: 16px;
  padding-bottom: 64px;
  display: flex;
  & div {
    /* max-width: 1440px;
    margin: 0 auto; */
    height: 24px;
    display: flex;
    align-items: flex-start;
    /* justify-content: center; */
  }

  & a {
    height: 100%;
  }

  & img {
    /* filter: grayscale(); */
    /* outline: 1px dashed; */
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    margin-right: 24px;

    &.fx {
      max-width: calc(100% + 17px);
      max-height: calc(100% + 17px);
    }
  }
}
@media (max-width: 512px) {
  .logos {
    margin-top: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    & img {
      margin-right: 16px;
      &.mn {
        margin-right: 0;
      }
    }
    & > div {
      margin-bottom: 16px;
    }
  }
}
</style>
