<script setup>
const internal = useInternal()
const toasts = useToasts()
defineProps({
  toast: {
    type: Object,
    required: true
  }
})
const close = id => toasts.removeToast(id)
</script>

<template>
  <div :class="['toast', { error: toast?.error }]">
    <vContent
      v-interpolation="() => close(toast.id)"
      :content="toast.content"
      class="pt"
    />
    <div>
      <svg
        v-if="!internal.isOnline"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        class="icon"
        @click="toasts.removeToast(toast.id)"
      >
        <path
          fill="currentColor"
          d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
        />
      </svg>
    </div>
  </div>
</template>

<style scoped>
.toast {
  background-color: var(--brand-color);
  margin-bottom: 8px;
  min-height: 59px;
  border-radius: 3px;
  color: white;
  /* box-shadow: var(--box-shadow); */
  padding: 16px 16px 0;
  font-size: 13px;
  display: grid;
  grid-template-columns: auto 24px;
  gap: 16px;

  &.error {
    background-color: red;
  }
}

.pt {
  padding: 4px 0;
}

:deep(strong) {
  font-weight: 800;
}

.icon {
  vertical-align: middle;
  cursor: pointer;
}
</style>
