<template>
  <div class="social">
    <h5>Prati nas i budi u toku</h5>
    <a
      href="https://www.facebook.com/stillesnamestaj"
      rel="nofollow noopener"
    >Facebook</a>
    <a
      href="https://www.youtube.com/channel/UCt4ljQhJPd5sBkwHFpL4udQ"
      rel="nofollow noopener"
    >YouTube</a>
    <a
      href="https://www.instagram.com/stilles_namestaj_po_meri"
      rel="nofollow noopener"
    >Instagram</a>
  </div>
</template>

<style scoped>
.social {
  padding-top: 32px;
}
a {
  display: block;
  color: rgb(var(--color-rgb) / .5);
    padding: 4px 0;
    font-size: 14px;
}
h5 {
  color: rgb(var(--color-rgb) / .5);
  padding-bottom: 16px;
    font-weight: 800;
    line-height: 1;
}
@media (max-width: 512px) {
  h5 {
    font-size: 24px;
  }
  a {
    padding: 8px 0;
    font-size: 18px;
  }
}
</style>
